<template>
  <div v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)" class="hl-full-container flexVertical">
    <!-- 表单s -->
    <el-drawer :title="dailogTitle" :visible.sync="dialogFormVisible" direction="rtl" size="100%" :modal="false"
      :destroy-on-close="true" class="el-drawer-hl" @close="handleClose">
      <el-form ref="elForm" :model="formData" :disabled="submitType == 'view' ? true : false" :rules="rules"
        size="medium" class="padding15" label-width="120px">
        <el-col :span="24">
          <el-row>
            <el-col :span="12">
              <el-form-item label="改造年度" prop="year">
                <el-input v-model="formData.year" placeholder="请输入改造年度" clearable :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="实施部门" prop="implementDept">
                <el-input v-model="formData.implementDept" placeholder="请输入实施部门" clearable
                  :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="镇街道" prop="street">
                <el-input v-model="formData.street" placeholder="请输入镇街道" clearable
                  :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属区划" prop="belongDivision">
                <el-input v-model="formData.belongDivision" placeholder="请输入所属区划" clearable
                  :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="姓名" prop="name">
                <el-input v-model="formData.name" placeholder="请输入姓名" clearable :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="身份证号" prop="idCode">
                <el-input v-model="formData.idCode" placeholder="请输入身份证号" clearable
                  :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="年龄" prop="age">
                <el-input v-model="formData.age" placeholder="请输入年龄" clearable :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否高龄" prop="oldAge">
                <el-input v-model="formData.oldAge" placeholder="请输入是否高龄" clearable
                  :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="联系方式" prop="tel">
                <el-input v-model="formData.tel" placeholder="请输入联系方式" clearable :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="家庭住址" prop="chargeIdType">
                <el-input v-model="formData.chargeIdType" placeholder="请输入家庭住址" clearable
                  :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="家庭类型" prop="familyType">
                <el-input v-model="formData.familyType" placeholder="请输入家庭类型" clearable
                  :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="评估机构" prop="evaluateAgency">
                <el-input v-model="formData.evaluateAgency" placeholder="请输入评估机构" clearable
                  :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="评估人" prop="evaluator">
                <el-input v-model="formData.evaluator" placeholder="请输入评估人" clearable
                  :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="评估时间" prop="subject">
                <!-- <el-input v-model="formData.subject" placeholder="请输入评估时间" clearable
                  :style="{ width: '100%' }"></el-input> -->
                  <el-date-picker v-model="formData.subject" type="date" placeholder="选择日期" value-format="timestamp">
                  </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="评估结果" prop="evaluateResult">
                <el-input v-model="formData.evaluateResult" placeholder="请输入评估结果" clearable
                  :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否改造" prop="renovate">
                <el-input v-model="formData.renovate" placeholder="请输入是否改造" clearable
                  :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="改造项目" prop="renovateProject">
                <el-input v-model="formData.renovateProject" placeholder="请输入改造项目" clearable
                  :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="改造资金" prop="renovateMoney">
                <el-input v-model="formData.renovateMoney" placeholder="请输入改造资金" clearable
                  :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="资金来源" prop="moneySource">
                <el-input v-model="formData.moneySource" placeholder="请输入资金来源" clearable
                  :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="改造机构" prop="renovateAgency">
                <el-input v-model="formData.renovateAgency" placeholder="请输入改造机构" clearable
                  :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="竣工时间" prop="completeTime">
                <!-- <el-input v-model="formData.completeTime" placeholder="请输入竣工时间" clearable
                  :style="{ width: '100%' }"></el-input> -->
                  <el-date-picker v-model="formData.completeTime" type="date" placeholder="选择日期" value-format="timestamp">
                  </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否验收" prop="approve">
                <el-input v-model="formData.approve" placeholder="请输入是否验收" clearable
                  :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="验收机构" prop="approveAgency">
                <el-input v-model="formData.approveAgency" placeholder="请输入验收机构" clearable
                  :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="验收时间" prop="approveTime">
                <!-- <el-input v-model="formData.approveTime" placeholder="请输入验收时间" clearable
                  :style="{ width: '100%' }"></el-input> -->
                  <el-date-picker v-model="formData.approveTime" type="date" placeholder="选择日期" value-format="timestamp">
                  </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="验收结果" prop="approveResult">
                <el-input v-model="formData.approveResult" placeholder="请输入验收结果" clearable
                  :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="改造类别" prop="renovateType">
                <el-input v-model="formData.renovateType" placeholder="请输入改造类别" clearable
                  :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="24">
          <el-row>
            <el-form-item size="large" style="text-align: center">
              <el-button v-show="submitType != 'view' ? true : false" class="qconfirm" type="primary" size="medium"
                @click="submitForm()">提交</el-button>
              <el-button v-show="submitType != 'view' ? true : false" class="qcancle" size="medium"
                @click="resetForm()">
                重置
              </el-button>
            </el-form-item>
          </el-row>
        </el-col>
      </el-form>
    </el-drawer>

    <div class="search">
      <el-form label-width="100px" :model="form" ref="form" inline>
        <el-form-item label="负责人姓名">
          <el-input size="small" clearable v-model="form.name" placeholder="请输入负责人姓名"></el-input>
        </el-form-item>
        <el-form-item label="改造年度">
          <el-select v-model="form.year" placeholder="请选择改造年度">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" icon="el-icon-search" @click="dataRefresh">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="toolbar">
      <el-button type="text" plain size="mini" icon="el-icon-upload2">
        <a href="/static/老人信息管理模板.xlsx" download="老人信息管理模板.xlsx">
          模板下载
        </a>
      </el-button>
      <el-upload :headers="token" :action="xlsAreaAction" :show-file-list="false" :before-upload="onUploadBefore"
        :on-success="onXlsUploadSuccess" accept=".xlsx, .xls" style="margin: 0 0.7rem">
        <el-button type="success" plain size="mini" icon="el-icon-upload">
          导入
        </el-button>
      </el-upload>

      <el-button type="primary" plain size="mini" icon="el-icon-download" @click="Download">
        导出
      </el-button>

      <!-- <el-button
        type="primary"
        plain
        size="mini"
        icon="el-icon-plus"
        @click="Add"
      >
        新增
      </el-button> -->
      <!-- <el-button
        type="danger"
        plain
        size="mini"
        icon="el-icon-delete"
        @click="Del"
      >
        删除
      </el-button> -->
    </div>
    <hltable :isNumber="false" :columnEdit="TableOptions.columnEdit" :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag" :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows" :apiurl="TableOptions.apiurl" @getSelectedRows="getSelectedRows">
      <div slot-scope="selected" slot="EditColumn">
        <el-tooltip effect="dark" content="查看" placement="top">
          <el-button type="warning" size="mini" icon="el-icon-search" circle
            @click="View(selected.singlerow)"></el-button>
        </el-tooltip>
        <!-- <el-tooltip effect="dark" content="编辑" placement="top">
          <el-button type="primary" icon="el-icon-edit" size="mini" circle
            @click="Edit(selected.singlerow)"></el-button>
        </el-tooltip> -->
      </div>
    </hltable>
  </div>
</template>

<script>
import { hltable } from "@/components";
export default {
  name: "rural",
  components: { hltable },
  data() {
    return {
      loading: false,
      token: { token: localStorage.getItem("systoken") },
      // 表单内容
      dailogTitle: "",
      dialogFormVisible: false,
      submitType: "",
      // 查询条件
      form: {
        name: "",
      },
      // 附件预览列表
      imgList: [],
      fileList: [],
      // 导入接口
      xlsAreaAction: "/api/elderlyOrientedReform/importData.json",
      // 新增/修改 表单
      timeValue: [],
      formData: {
        name: "",
        path: "",
        elderlyNum: "",
        startTime: "",
        endTime: "",
      },
      options: [{
        value: '2022',
        label: '2022'
      }, {
        value: '2023',
        label: '2023'
      }],
      // 表单规则
      rules: {
        name: [
          {
            required: true,
            message: "请输入负责人姓名",
            trigger: "blur",
          },
        ],
        elderlyNum: [
          {
            required: true,
            message: "请输入访问数量",
            trigger: "blur",
          },
        ],
      },
      // 列表设置
      TableOptions: {
        selectedRows: [],
        apiurl: "api/getElderOrientedDataList",
        refreshFlag: "",
        columnEdit: true,
        column: [
          {
            label: "改造年度",
            prop: "year",
            checked: true,
          },
          {
            label: "实施部门",
            prop: "implementDept",
            checked: true,
          },
          {
            label: "镇街道",
            prop: "street",
            checked: true,
          },
          {
            label: "姓名",
            prop: "name",
            checked: true,
          },
          {
            label: "改造项目",
            prop: "renovateProject",
            checked: true,
          },
          {
            label: "家庭类型",
            prop: "familyType",
            checked: true,
          },
        ],
      },
    };
  },
  async mounted() {
    await this.loadData();
    this.dataRefresh();
  },
  methods: {
    // 查询
    dataRefresh() {
      this.TableOptions.refreshFlag = new Date().getTime().toString();
    },
    // 时间格式化
    timeFormatter(row, column, cellValue) {
      return cellValue ? this.$timestampTostr(cellValue) : "";
    },
    // 加载数据
    async loadData() { },
    // 新增
    Add() {
      this.setformType("add", "新增");
    },
    // 查看
    View(row) {
      this.setformType("view", "查看");
      this.loadform(row);
    },
    // 编辑
    Edit(row) {
      this.setformType("edit", "编辑");
      this.loadform(row);
    },
    // 设置表单状态
    setformType(type, title) {
      this.dialogFormVisible = true;
      this.dailogTitle = title;
      this.submitType = type;
    },
    loadform(row) {
      const rowinfo = Object.assign({}, row);
      this.formData = rowinfo;
      this.imgList = rowinfo.path.split(",");
      this.imgList.forEach((file) => {
        this.fileList.push({ url: file });
      });
      this.timeValue = [rowinfo.rentalTime, rowinfo.returnTime];
    },
    // 弹窗关闭前处理
    handleClose() {
      this.resetForm();
    },
    // 删除
    Del() {
      if (this.TableOptions.selectedRows.length == 0) {
        this.$message({
          type: "error",
          message: "请勾选下方表格中的数据",
        });
        return false;
      }
      var ids = [];
      this.TableOptions.selectedRows.forEach((key) => {
        ids.push(key.id);
      });
      this.$confirm("确定要进行删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store
          .dispatch("api/getElderOrientedDleteData", { ids: ids })
          .then((response) => {
            if (response.success) {
              this.$message.success("操作成功");
              this.dataRefresh();
            } else {
              this.$message.error(response.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    // 提交
    submitForm() {
      if (this.imgList.length == 0) {
        this.$message("请选择图片");
        return;
      }
      if (this.timeValue.length == 0) {
        this.$message("请选择时间");
        return;
      }
      this.$refs["elForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          const copyForm = Object.assign({}, this.formData);
          if (this.submitType == "add") {
            copyForm.id = "";
          }
          copyForm.path = this.imgList.join(",");
          copyForm.startTime = this.timeValue[0];
          copyForm.endTime = this.timeValue[1];
          this.$store
            .dispatch("api/getElderOrientedSaveData", copyForm)
            .then((response) => {
              if (response.success) {
                this.$message.success("操作成功");
                this.dataRefresh();
              } else {
                this.$message.error(response.message);
              }
              this.dialogFormVisible = false;
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 重置
    resetForm() {
      const th = this;
      Object.keys(this.formData).forEach(function (key) {
        th.formData[key] = "";
      });
      this.imgList = [];
      this.fileList = [];
      this.timeValue = [];
    },
    // 文件上传前
    onUploadBefore() {
      this.loading = true;
    },
    // 导入
    onXlsUploadSuccess(response) {
      if (response.success) {
        this.$message({
          type: "success",
          message: "导入成功",
        });
        this.dataRefresh();
      } else {
        this.$message({
          type: "error",
          message: "导入失败",
        });
      }
      this.loading = false;
    },
    /**
     * 附件上传
     */
    success(response, file) {
      this.imgList.push(response.result);
    },
    beforeUpload(file) {
      let isAccept = new RegExp("image/*").test(file.type);
      if (!isAccept) {
        this.$message.error("应该选择图片类型的文件");
      }
      return isAccept;
    },
    handleRemove(file, fileList) {
      this.imgList = [];
      if (fileList.length > 0) {
        fileList.forEach((item) => {
          this.imgList.push(item.response ? item.response.result : item.url);
        });
      }
    },
    // 导出
    async Download() {
      this.loading = true;
      const downloadFrom = Object.assign({}, this.form);
      this.$store
        .dispatch("api/getElderOrientedExportData", downloadFrom)
        .then((response) => {
          let blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          let fileName = "适老化改造" + ".xlsx";
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            let link = document.createElement("a");

            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            // 释放内存
            window.URL.revokeObjectURL(link.href);
          }
          this.loading = false;
          this.$message.success("导出成功");
        });
    },

    // 获取选中项
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/tabletoptool.scss";
</style>
