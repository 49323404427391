<template>
  <div v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)" class="hl-full-container flexVertical">
    <!-- 表单s -->
    <el-drawer :title="dailogTitle" :visible.sync="dialogFormVisible" direction="rtl" size="100%" :modal="false"
      :destroy-on-close="true" class="el-drawer-hl" @close="handleClose">
      <el-form ref="elForm" :model="formData" :disabled="submitType == 'view' ? true : false" :rules="rules"
        size="medium" class="padding15" label-width="120px">
        <el-col :span="24">
          <el-row>
            <el-col :span="12">
              <el-form-item label="所属区划" prop="belongDivision">
                <el-input v-model="formData.belongDivision" placeholder="请输入所属区划" clearable :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设施名称" prop="facilityName">
                <el-input v-model="formData.facilityName" placeholder="请输入设施名称" clearable :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="运营状态" prop="status">
                <el-input v-model="formData.status" placeholder="请输入运营状态" clearable :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="社会信用代码" prop="socialCode">
                <el-input v-model="formData.socialCode" placeholder="请输入统一社会信用代码" clearable :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="负责人姓名" prop="chargeName">
                <el-input v-model="formData.chargeName" placeholder="请输入负责人姓名" clearable :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="负责人手机号" prop="chargeTel">
                <el-input v-model="formData.chargeTel" placeholder="请输入负责人手机号" clearable :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="运营时间" prop="operateTime">
                <!-- <el-input v-model="formData.operateTime" placeholder="请输入运营时间" clearable :style="{ width: '100%' }">
                </el-input> -->
                <el-date-picker v-model="formData.operateTime" type="date" placeholder="选择日期" value-format="timestamp">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="兴办主体" prop="subject">
                <el-input v-model="formData.subject" placeholder="请输入兴办主体" clearable :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="评估星级" prop="star">
                <el-input v-model="formData.star" placeholder="请输入评估星级" clearable :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地址" prop="address">
                <el-input v-model="formData.address" placeholder="请输入地址" clearable :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="占地面积" prop="coverArea">
                <el-input v-model="formData.coverArea" placeholder="请输入占地面积" clearable :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="床位数" prop="bedNum">
                <el-input v-model="formData.bedNum" placeholder="请输入床位数" clearable :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="可留宿床位数" prop="overnightBedNum">
                <el-input v-model="formData.overnightBedNum" placeholder="请输入可留宿床位数" clearable
                  :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="建设方式" prop="constructMethod">
                <el-input v-model="formData.constructMethod" placeholder="请输入建设方式" clearable :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="建筑面积" prop="buildArea">
                <el-input v-model="formData.buildArea" placeholder="请输入建筑面积" clearable :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="入住老年人数" prop="elderlyNum">
                <el-input v-model="formData.elderlyNum" placeholder="请输入入住老年人数" clearable :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="从业人员数" prop="employedNum">
                <el-input v-model="formData.employedNum" placeholder="请输入从业人员数" clearable :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="图片" prop="img">
                <!-- <el-input v-model="formData.img" placeholder="请输入统一社会信用代码" clearable :style="{ width: '100%' }"> -->
                <!-- </el-input> -->
                <el-upload
                  :file-list="fileList"
                  :headers="token"
                  :on-remove="handleRemove"
                  :on-success="success"
                  :before-upload="beforeUpload"
                  accept=".jpg,.png"
                  action="/api/ruralHappinessInstitute/uploadImg.json"
                  list-type="picture-card"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="24">
          <el-row>
            <el-form-item size="large" style="text-align: center">
              <el-button v-show="submitType != 'view' ? true : false" class="qconfirm" type="primary" size="medium"
                @click="submitForm()">提交</el-button>
              <el-button v-show="submitType != 'view' ? true : false" class="qcancle" size="medium"
                @click="resetForm()">
                重置
              </el-button>
            </el-form-item>
          </el-row>
        </el-col>
      </el-form>
    </el-drawer>

    <div class="search">
      <el-form label-width="100px" :model="form" ref="form" inline>
        <el-form-item label="负责人姓名">
          <el-input size="small" clearable v-model="form.chargeName" placeholder="请输入负责人姓名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" icon="el-icon-search" @click="dataRefresh">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="toolbar">
      <el-button type="text" plain size="mini" icon="el-icon-upload2">
        <a href="/static/老人信息管理模板.xlsx" download="老人信息管理模板.xlsx">
          模板下载
        </a>
      </el-button>
      <el-upload :headers="token" :action="xlsAreaAction" :show-file-list="false" :before-upload="onUploadBefore"
        :on-success="onXlsUploadSuccess" accept=".xlsx, .xls" style="margin: 0 0.7rem">
        <el-button type="success" plain size="mini" icon="el-icon-upload">
          导入
        </el-button>
      </el-upload>

      <el-button type="primary" plain size="mini" icon="el-icon-download" @click="Download">
        导出
      </el-button>

      <el-button type="primary" plain size="mini" icon="el-icon-plus" @click="Add">
        新增
      </el-button>
      <el-button type="danger" plain size="mini" icon="el-icon-delete" @click="Del">
        删除
      </el-button>
    </div>
    <hltable :isNumber="false" :columnEdit="TableOptions.columnEdit" :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag" :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows" :apiurl="TableOptions.apiurl" @getSelectedRows="getSelectedRows">
      <div slot-scope="selected" slot="EditColumn">
        <el-tooltip effect="dark" content="查看" placement="top">
          <el-button type="warning" size="mini" icon="el-icon-search" circle
            @click="View(selected.singlerow)"></el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="编辑" placement="top">
          <el-button type="primary" icon="el-icon-edit" size="mini" circle
            @click="Edit(selected.singlerow)"></el-button>
        </el-tooltip>
      </div>
    </hltable>
  </div>
</template>

<script>
import { hltable } from "@/components";
export default {
  name: "rural",
  components: { hltable },
  data() {
    return {
      loading: false,
      token: { token: localStorage.getItem("systoken") },
      // 表单内容
      dailogTitle: "",
      dialogFormVisible: false,
      submitType: "",
      // 查询条件
      form: {
        name: "",
      },
      // 附件预览列表
      imgList: [],
      fileList: [],
      // 导入接口
      xlsAreaAction: "/api/ruralHappinessInstitute/importData.json",
      // 新增/修改 表单
      timeValue: [],
      formData: {
        name: "",
        img: "",
        elderlyNum: "",
        startTime: "",
        endTime: "",
      },
      // 表单规则
      rules: {
        chargeName: [
          {
            required: true,
            message: "请输入负责人姓名",
            trigger: "blur",
          },
        ],
        facilityName: [
          {
            required: true,
            message: "请输入设备名称",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "请输入运营状态",
            trigger: "blur",
          },
        ],
        operateTime: [
          {
            required: true,
            message: "请选择运营时间",
            trigger: "blur",
          },
        ],
      },
      // 列表设置
      TableOptions: {
        selectedRows: [],
        apiurl: "api/getHappinessInstituteDataList",
        refreshFlag: "",
        columnEdit: true,
        column: [
          {
            label: "设备名称",
            prop: "facilityName",
            checked: true,
          },
          {
            label: "运营状态",
            prop: "status",
            checked: true,
          },
          {
            label: "负责人姓名",
            prop: "chargeName",
            checked: true,
          },
          {
            label: "运营时间",
            prop: "operateTime",
            checked: true,
            formatter: this.timeFormatter,
          },
          {
            label: "地址",
            prop: "address",
            checked: true,
          }
        ],
      },
    };
  },
  async mounted() {
    await this.loadData();
    this.dataRefresh();
  },
  methods: {
    // 查询
    dataRefresh() {
      this.TableOptions.refreshFlag = new Date().getTime().toString();
    },
    // 时间格式化
    timeFormatter(row, column, cellValue) {
      // return cellValue ? this.$timestampTostr(cellValue) : "";
      const date = new Date(cellValue * (cellValue.toString().length === 10 ? 1000 : 1));
      // 获取年份
      const year = date.getFullYear();
      // 获取月份，注意月份是从 0 开始的，所以要加 1，并且保证是两位数
      const month = String(date.getMonth() + 1).padStart(2, '0');
      // 获取日期，保证是两位数
      const day = String(date.getDate()).padStart(2, '0');
      // 拼接成 yyyy-mm-dd 格式
      return `${year}-${month}-${day}`;
    },
    // 加载数据
    async loadData() { },
    // 新增
    Add() {
      this.setformType("add", "新增");
    },
    // 查看
    View(row) {
      this.setformType("view", "查看");
      this.loadform(row);
    },
    // 编辑
    Edit(row) {
      this.setformType("edit", "编辑");
      this.loadform(row);
    },
    // 设置表单状态
    setformType(type, title) {
      this.dialogFormVisible = true;
      this.dailogTitle = title;
      this.submitType = type;
    },
    loadform(row) {
      const rowinfo = Object.assign({}, row);
      this.formData = rowinfo;
      this.imgList = rowinfo.img.split(",");
      this.imgList.forEach((file) => {
        this.fileList.push({ url: file });
      });
      this.timeValue = [rowinfo.rentalTime, rowinfo.returnTime];
    },
    // 弹窗关闭前处理
    handleClose() {
      this.resetForm();
    },
    // 删除
    Del() {
      if (this.TableOptions.selectedRows.length == 0) {
        this.$message({
          type: "error",
          message: "请勾选下方表格中的数据",
        });
        return false;
      }
      var ids = [];
      this.TableOptions.selectedRows.forEach((key) => {
        ids.push(key.id);
      });
      this.$confirm("确定要进行删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store
          .dispatch("api/getHappinessInstituteDeleteData", { ids: ids })
          .then((response) => {
            if (response.success) {
              this.$message.success("操作成功");
              this.dataRefresh();
            } else {
              this.$message.error(response.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    // 提交
    submitForm() {
      // if (this.imgList.length == 0) {
      //   this.$message("请选择图片");
      //   return;
      // }
      // if (this.timeValue.length == 0) {
      //   this.$message("请选择时间");
      //   return;
      // }
      this.$refs["elForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          const copyForm = Object.assign({}, this.formData);
          if (this.submitType == "add") {
            copyForm.id = "";
          }
          copyForm.img = this.imgList.join(",");
          copyForm.startTime = this.timeValue[0];
          copyForm.endTime = this.timeValue[1];
          this.$store
            .dispatch("api/getHappinessInstituteSaveData", copyForm)
            .then((response) => {
              if (response.success) {
                this.$message.success("操作成功");
                this.dataRefresh();
              } else {
                this.$message.error(response.message);
              }
              this.dialogFormVisible = false;
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 重置
    resetForm() {
      const th = this;
      Object.keys(this.formData).forEach(function (key) {
        th.formData[key] = "";
      });
      this.imgList = [];
      this.fileList = [];
      this.timeValue = [];
    },
    // 文件上传前
    onUploadBefore() {
      this.loading = true;
    },
    // 导入
    onXlsUploadSuccess(response) {
      if (response.success) {
        this.$message({
          type: "success",
          message: "导入成功",
        });
        this.dataRefresh();
      } else {
        this.$message({
          type: "error",
          message: "导入失败",
        });
      }
      this.loading = false;
    },
    /**
     * 附件上传
     */
    success(response, file) {
      this.imgList.push(response.result);
    },
    beforeUpload(file) {
      let isAccept = new RegExp("image/*").test(file.type);
      if (!isAccept) {
        this.$message.error("应该选择图片类型的文件");
      }
      return isAccept;
    },
    handleRemove(file, fileList) {
      this.imgList = [];
      if (fileList.length > 0) {
        fileList.forEach((item) => {
          this.imgList.push(item.response ? item.response.result : item.url);
        });
      }
    },
    // 导出
    async Download() {
      this.loading = true;
      const downloadFrom = Object.assign({}, this.form);
      this.$store
        .dispatch("api/getHappinessInstituteExportData", downloadFrom)
        .then((response) => {
          let blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          let fileName = "农村幸福院" + ".xlsx";
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            let link = document.createElement("a");

            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            // 释放内存
            window.URL.revokeObjectURL(link.href);
          }
          this.loading = false;
          this.$message.success("导出成功");
        });
    },

    // 获取选中项
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/tabletoptool.scss";
</style>
