<template>
  <div
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    class="hl-full-container flexVertical"
  >
    <!-- 表单s -->
    <el-drawer
      :title="dailogTitle"
      :visible.sync="dialogFormVisible"
      direction="rtl"
      size="100%"
      :modal="false"
      :destroy-on-close="true"
      class="el-drawer-hl"
      @close="handleClose"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :disabled="submitType == 'view' ? true : false"
        :rules="rules"
        size="medium"
        class="padding15"
        label-width="120px"
      >
        <el-col :span="24">
          <el-row>
            <el-col :span="12">
              <el-form-item label="所属区划" prop="belongDivision">
                <el-input
                  v-model="formData.belongDivision"
                  placeholder="请输入所属区划"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="机构名称" prop="name">
                <el-input
                  v-model="formData.name"
                  placeholder="请输入机构名称"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="运营状态" prop="status">
                <el-input
                  v-model="formData.status"
                  placeholder="请输入运营状态"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="机构类别" prop="category">
                <el-input
                  v-model="formData.category"
                  placeholder="请输入机构类别"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="法人性质" prop="nature">
                <el-input
                  v-model="formData.nature"
                  placeholder="请输入法人性质"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="统一社会信用代码" prop="socialCode">
                <el-input
                  v-model="formData.socialCode"
                  placeholder="请输入统一社会信用代码"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="法定代表人" prop="legalRepresent">
                <el-input
                  v-model="formData.legalRepresent"
                  placeholder="请输入法定代表人"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="法定代表人手机号" prop="legalTel">
                <el-input
                  v-model="formData.legalTel"
                  placeholder="请输入法定代表人手机号"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="机构地址" prop="address">
                <el-input
                  v-model="formData.address"
                  placeholder="请输入机构地址"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="机构建设情况" prop="development">
                <el-input
                  v-model="formData.development"
                  placeholder="请输入机构建设情况"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="运营方式" prop="operateWay">
                <el-input
                  v-model="formData.operateWay"
                  placeholder="请输入运营方式"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="兴办主体" prop="subject">
                <el-input
                  v-model="formData.subject"
                  placeholder="请输入兴办主体"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="医疗服务兴办方式" prop="establishMethod">
                <el-input
                  v-model="formData.establishMethod"
                  placeholder="请输入医疗服务兴办方式"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="医疗服务方式" prop="service">
                <el-input
                  v-model="formData.service"
                  placeholder="请输入医疗服务方式"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="评估星级" prop="star">
                <el-input
                  v-model="formData.star"
                  placeholder="请输入评估星级"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="评估日期" prop="evaluateDate">
                <el-date-picker
                  v-model="formData.evaluateDate"
                  type="date"
                  placeholder="选择日期"
                  value-format="timestamp"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="成立日期" prop="establishDate">
                <el-date-picker
                  v-model="formData.establishDate"
                  type="date"
                  placeholder="选择日期"
                  value-format="timestamp"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="床位总数" prop="bedTotal">
                <el-input
                  v-model="formData.bedTotal"
                  placeholder="请输入床位总数"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="护理型床位数" prop="nurseNum">
                <el-input
                  v-model="formData.nurseNum"
                  placeholder="请输入护理型床位数"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="入住老年人数" prop="elderlyNum">
                <el-input
                  v-model="formData.elderlyNum"
                  placeholder="请输入入住老年人数"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="从业人员数" prop="employedNum">
                <el-input
                  v-model="formData.employedNum"
                  placeholder="请输入从业人员数"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="原许可备案证号" prop="licenseCode">
                <el-input
                  v-model="formData.licenseCode"
                  placeholder="请输入原许可备案证号"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="许可备案时间" prop="licenseTime">
                <el-date-picker
                  v-model="formData.licenseTime"
                  type="date"
                  placeholder="选择日期"
                  value-format="timestamp"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="养老机构备案号" prop="institutionCode">
                <el-input
                  v-model="formData.institutionCode"
                  placeholder="请输入养老机构备案号"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="开始运营时间" prop="operateTime">
                <el-date-picker
                  v-model="formData.operateTime"
                  type="date"
                  placeholder="选择日期"
                  value-format="timestamp"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="房屋建筑面积（平方米）" prop="buildArea">
                <el-input
                  v-model="formData.buildArea"
                  placeholder="请输入房屋建筑面积（平方米）"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="房屋占地面积（平方米）" prop="coverArea">
                <el-input
                  v-model="formData.coverArea"
                  placeholder="请输入房屋占地面积（平方米）"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="能力完好最少" prop="abilityMin">
                <el-input
                  v-model="formData.abilityMin"
                  placeholder="请输入能力完好最少"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="能力完好最多" prop="abilityMax">
                <el-input
                  v-model="formData.abilityMax"
                  placeholder="请输入能力完好最多"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="轻度失能最少" prop="mildDisabilityMin">
                <el-input
                  v-model="formData.mildDisabilityMin"
                  placeholder="请输入轻度失能最少"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="轻度失能最多" prop="mildDisabilityMax">
                <el-input
                  v-model="formData.mildDisabilityMax"
                  placeholder="请输入轻度失能最多"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="中度失能最少" prop="modeDisabilityMin">
                <el-input
                  v-model="formData.modeDisabilityMin"
                  placeholder="请输入中度失能最少"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="中度失能最多" prop="modeDisabilityMax">
                <el-input
                  v-model="formData.modeDisabilityMax"
                  placeholder="请输入中度失能最多"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="重度失能最少" prop="severeDisabilityMin">
                <el-input
                  v-model="formData.severeDisabilityMin"
                  placeholder="请输入重度失能最少"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="重度失能最多" prop="severeDisabilityMax">
                <el-input
                  v-model="formData.severeDisabilityMax"
                  placeholder="请输入重度失能最多"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="24">
          <el-row>
            <el-form-item size="large" style="text-align: center">
              <el-button
                v-show="submitType != 'view' ? true : false"
                class="qconfirm"
                type="primary"
                size="medium"
                @click="submitForm()"
                >提交</el-button
              >
              <el-button
                v-show="submitType != 'view' ? true : false"
                class="qcancle"
                size="medium"
                @click="resetForm()"
              >
                重置
              </el-button>
            </el-form-item>
          </el-row>
        </el-col>
      </el-form>
    </el-drawer>

    <div class="search">
      <el-form label-width="100px" :model="form" ref="form" inline>
        <el-form-item label="机构名称">
          <el-input
            size="small"
            clearable
            v-model="form.name"
            placeholder="请输入机构名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="法定代表人">
          <el-input
            size="small"
            clearable
            v-model="form.legalRepresent"
            placeholder="请输入法定代表人"
          ></el-input>
        </el-form-item>
        <el-form-item label="社会信用代码">
          <el-input
            size="small"
            clearable
            v-model="form.socialCode"
            placeholder="请输入社会信用代码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="toolbar">
      <el-upload
        :headers="token"
        :action="xlsAreaAction"
        :show-file-list="false"
        :before-upload="onUploadBefore"
        :on-success="onXlsUploadSuccess"
        accept=".xlsx, .xls"
        style="margin: 0 0.7rem"
      >
        <el-button type="success" plain size="mini" icon="el-icon-upload">
          导入
        </el-button>
      </el-upload>

      <el-button
        type="primary"
        plain
        size="mini"
        icon="el-icon-download"
        @click="Download"
      >
        导出
      </el-button>
      <el-button
        type="primary"
        plain
        size="mini"
        icon="el-icon-plus"
        @click="Add"
        >新增</el-button
      >
      <el-button
        type="danger"
        plain
        size="mini"
        icon="el-icon-delete"
        @click="Del"
        >删除</el-button
      >
    </div>
    <hltable
      :isNumber="false"
      :columnEdit="TableOptions.columnEdit"
      :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag"
      :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows"
      :apiurl="TableOptions.apiurl"
      @getSelectedRows="getSelectedRows"
    >
      <div slot-scope="selected" slot="EditColumn">
        <el-tooltip effect="dark" content="查看" placement="top">
          <el-button
            type="warning"
            size="mini"
            icon="el-icon-search"
            circle
            @click="View(selected.singlerow)"
          ></el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="编辑" placement="top">
          <el-button
            type="primary"
            icon="el-icon-edit"
            size="mini"
            circle
            @click="Edit(selected.singlerow)"
          ></el-button>
        </el-tooltip>
      </div>
    </hltable>
  </div>
</template>

<script>
import { hltable } from "@/components";
export default {
  name: "fwrygl",
  components: { hltable },
  data() {
    return {
      // 导入接口
      xlsAreaAction: "/api/elderlyCareInstitution/importData.json",
      loading: false,
      token: { token: localStorage.getItem("systoken") },
      // 表单内容
      dailogTitle: "",
      dialogFormVisible: false,
      submitType: "",
      // 查询条件
      form: {
        name: "",
        legalRepresent: "",
        code: "",
      },
      // 新增/修改 表单
      formData: {
        belongDivision: "",
        name: "",
        status: "",
        category: "",
        nature: "",
        socialCode: "",
        legalRepresent: "",
        legalTel: "",
        address: "",
        development: "",
        operateWay: "",
        subject: "",
        establishMethod: "",
        service: "",
        star: "",
        evaluateDate: "",
        establishDate: "",
        bedTotal: "",
        nurseNum: "",
        elderlyNum: "",
        employedNum: "",
        licenseCode: "",
        licenseTime: "",
        institutionCode: "",
        operateTime: "",
        buildArea: "",
        coverArea: "",
        abilityMin: "",
        abilityMax: "",
        mildDisabilityMin: "",
        mildDisabilityMax: "",
        modeDisabilityMin: "",
        modeDisabilityMax: "",
        severeDisabilityMin: "",
        severeDisabilityMax: "",
      },
      // 表单规则
      rules: {
        // name: [
        //   {
        //     required: true,
        //     message: "请输入机构名称",
        //     trigger: "blur",
        //   },
        // ],
        // director: [
        //   {
        //     required: true,
        //     message: "请输入负责人",
        //     trigger: "blur",
        //   },
        // ],
        // directorTel: [
        //   {
        //     required: true,
        //     pattern:
        //       /^(0|86|17951)?(13[0-9]|14[579]|15[012356789]|16[2567]|17[01235678]|18[0-9]|19[012356789])[0-9]{8}$/,
        //     message: "请输入正确的手机号",
        //     trigger: "blur",
        //   },
        // ],
      },
      // 列表设置
      TableOptions: {
        selectedRows: [],
        apiurl: "api/getelderlyCareInstitutionList",
        refreshFlag: "",
        columnEdit: true,
        column: [
          {
            label: "所属区划",
            prop: "belongDivision",
            checked: true,
            width: "150",
          },
          {
            label: "机构名称",
            prop: "name",
            checked: true,
            width: "150",
          },
          {
            label: "运营状态",
            prop: "status",
            checked: true,
            width: "150",
          },
          {
            label: "机构类别",
            prop: "category",
            checked: true,
            width: "150",
          },
          {
            label: "法人性质",
            prop: "nature",
            checked: true,
            width: "150",
          },
          {
            label: "统一社会信用代码",
            prop: "socialCode",
            checked: true,
            width: "150",
          },
          {
            label: "法定代表人",
            prop: "legalRepresent",
            checked: true,
            width: "150",
          },
          {
            label: "法定代表人手机号",
            prop: "legalTel",
            checked: true,
            width: "150",
          },
          {
            label: "机构地址",
            prop: "address",
            checked: true,
            width: "150",
          },
          {
            label: "机构建设情况",
            prop: "development",
            checked: true,
            width: "150",
          },
          {
            label: "运营方式",
            prop: "operateWay",
            checked: true,
            width: "150",
          },
          {
            label: "兴办主体",
            prop: "subject",
            checked: true,
            width: "150",
          },
          {
            label: "医疗服务兴办方式",
            prop: "establishMethod",
            checked: true,
            width: "150",
          },
          {
            label: "医疗服务方式",
            prop: "service",
            checked: true,
            width: "150",
          },
          {
            label: "评估星级",
            prop: "star",
            checked: true,
            width: "150",
          },
          {
            label: "评估日期",
            prop: "evaluateDate",
            checked: true,
            width: "150",
            formatter: this.timeFormatter,
          },
          {
            label: "成立日期",
            prop: "establishDate",
            checked: true,
            width: "150",
            formatter: this.timeFormatter,
          },
          {
            label: "床位总数",
            prop: "bedTotal",
            checked: true,
            width: "150",
          },
          {
            label: "护理型床位数",
            prop: "nurseNum",
            checked: true,
            width: "150",
          },
          {
            label: "入住老年人数",
            prop: "elderlyNum",
            checked: true,
            width: "150",
          },
          {
            label: "从业人员数",
            prop: "employedNum",
            checked: true,
            width: "150",
          },
          {
            label: "原许可备案证号",
            prop: "licenseCode",
            checked: true,
            width: "150",
          },
          {
            label: "许可备案时间",
            prop: "licenseTime",
            checked: true,
            width: "150",
            formatter: this.timeFormatter,
          },
          {
            label: "养老机构备案号",
            prop: "institutionCode",
            checked: true,
            width: "150",
          },
          {
            label: "开始运营时间",
            prop: "operateTime",
            checked: true,
            width: "150",
            formatter: this.timeFormatter,
          },
          {
            label: "房屋建筑面积（平方米）",
            prop: "buildArea",
            checked: true,
            width: "150",
          },
          {
            label: "房屋占地面积（平方米）",
            prop: "coverArea",
            checked: true,
            width: "150",
          },
          {
            label: "能力完好最少",
            prop: "abilityMin",
            checked: true,
            width: "150",
          },
          {
            label: "能力完好最多",
            prop: "abilityMax",
            checked: true,
            width: "150",
          },
          {
            label: "轻度失能最少",
            prop: "mildDisabilityMin",
            checked: true,
            width: "150",
          },
          {
            label: "轻度失能最多",
            prop: "mildDisabilityMax",
            checked: true,
            width: "150",
          },
          {
            label: "中度失能最少",
            prop: "modeDisabilityMin",
            checked: true,
            width: "150",
          },
          {
            label: "中度失能最多",
            prop: "modeDisabilityMax",
            checked: true,
            width: "150",
          },
          {
            label: "重度失能最少",
            prop: "severeDisabilityMin",
            checked: true,
            width: "150",
          },
          {
            label: "重度失能最多",
            prop: "severeDisabilityMax",
            checked: true,
            width: "150",
          },
        ],
      },

      typeList: [
        { id: "1", name: "公办民营" },
        { id: "2", name: "公办公营" },
        { id: "3", name: "民办民营" },
      ],
    };
  },
  async mounted() {
    await this.loadData();
    this.dataRefresh();
  },
  methods: {
    // 文件上传前
    onUploadBefore() {
      this.loading = true;
    },
    onXlsUploadSuccess(response) {
      if (response.success) {
        this.$message({
          type: "success",
          message: "导入成功",
        });
        this.dataRefresh();
      } else {
        this.$message({
          type: "error",
          message: "导入失败",
        });
      }
      this.loading = false;
    },
    // 导出
    async Download() {
      this.loading = true;
      const downloadFrom = Object.assign({}, this.form);
      this.$store
        .dispatch("api/getelderlyCareInstitutionExportData", downloadFrom)
        .then((response) => {
          let blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          let fileName = "养老机构管理" + ".xlsx";
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            let link = document.createElement("a");

            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            // 释放内存
            window.URL.revokeObjectURL(link.href);
          }
          this.loading = false;
          this.$message.success("导出成功");
        });
    },
    // 查询
    dataRefresh() {
      this.TableOptions.refreshFlag = new Date().getTime().toString();
    },
    // 加载数据
    async loadData() {},
    // 新增
    Add() {
      this.setformType("add", "新增");
    },
    // 查看
    View(row) {
      this.setformType("view", "查看");
      this.loadform(row);
    },
    // 编辑
    Edit(row) {
      this.setformType("edit", "编辑");
      this.loadform(row);
    },
    // 设置表单状态
    setformType(type, title) {
      this.dialogFormVisible = true;
      this.dailogTitle = title;
      this.submitType = type;
    },
    loadform(row) {
      const rowinfo = Object.assign({}, row);
      this.formData = rowinfo;
    },
    // 弹窗关闭前处理
    handleClose() {
      this.resetForm();
    },
    // 删除
    Del() {
      if (this.TableOptions.selectedRows.length == 0) {
        this.$message({
          type: "error",
          message: "请勾选下方表格中的数据",
        });
        return false;
      }
      var ids = [];
      this.TableOptions.selectedRows.forEach((key) => {
        ids.push(key.id);
      });
      this.$confirm("确定要进行删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store
          .dispatch("api/delelderlyCareInstitution", { ids: ids })
          .then((response) => {
            if (response.success) {
              this.$message.success("操作成功");
              this.dataRefresh();
            } else {
              this.$message.error(response.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    // 提交
    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          const copyForm = Object.assign({}, this.formData);
          if (this.submitType == "add") {
            copyForm.id = "";
          }
          this.$store
            .dispatch("api/saveelderlyCareInstitution", copyForm)
            .then((response) => {
              if (response.success) {
                this.$message.success("操作成功");
                this.dataRefresh();
              } else {
                this.$message.error(response.message);
              }
              this.dialogFormVisible = false;
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 重置
    resetForm() {
      const th = this;
      Object.keys(this.formData).forEach(function (key) {
        th.formData[key] = "";
      });
    },

    // 获取选中项
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows;
    },
    timeFormatter(row, column, cellValue) {
      // return cellValue ? this.$timestampTostr(cellValue) : "";
      const date = new Date(
        cellValue * (cellValue.toString().length === 10 ? 1000 : 1)
      );
      // 获取年份
      const year = date.getFullYear();
      // 获取月份，注意月份是从 0 开始的，所以要加 1，并且保证是两位数
      const month = String(date.getMonth() + 1).padStart(2, "0");
      // 获取日期，保证是两位数
      const day = String(date.getDate()).padStart(2, "0");
      // 拼接成 yyyy-mm-dd 格式
      return `${year}-${month}-${day}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/tabletoptool.scss";
</style>
