<template>
  <div class="hl-full-container flexVertical">
    <div class="lzcs-topImg">
      <el-carousel height="400px">
        <el-carousel-item v-for="item in imgList" :key="item">
          <img :src="item" class="carousel-img">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="lzcs-bottom">
      <div class="lzcs-btmLeft">
        <div class="header">
          <span>捐赠单位</span>
          <span>捐赠金额</span>
          <span>截止日期</span>
        </div>
        <vueSeamlessScroll :data="dataList" class="seamless-warp" :class-option="classOption" style="
          height: 100%;
          overflow: hidden;
          width: 100%;
          /* color: white; */
          font-size: 18px;
          text-align: center;
        ">
          <ul class="no-bullet">
            <li v-for="(item, index) in dataList" :key="index" style="padding: 10px 0; margin: 5px;list-style: none;">
              <span class="name">{{ item.name }}</span>
              <span class="Etiology">{{ item.sum }}</span>
              <span class="time">{{ item.time }}</span>
            </li>
          </ul>

        </vueSeamlessScroll>
      </div>
      <div class="lzcs-btnRight">
        <el-dialog title="临淄慈善" width="55%" :close-on-click-modal="false" :before-close="handleClose"
          :visible.sync="showEditer">
          <div class="edit-view">
            <div class="edit-title">
              <el-form :model="editorForm" label-width="120px" inline>
                <el-form-item label="标题：" label-width="80px">
                  <el-input style="width: 500px" size="small" v-model="editorForm.title" placeholder="请输入标题"></el-input>
                </el-form-item>
              </el-form>
              <el-button size="small" @click="saveEditor" type="primary">保存</el-button>
            </div>
            <div style="
            width: 100%;
            flex: 1;
            border: 1px solid #ccc;
            overflow-y: hidden;
            margin-top: 5px;
          ">
              <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig"
                :mode="mode" />
              <Editor style="height: calc(100% - 80px)" v-model="editorForm.content" :defaultConfig="editorConfig"
                :mode="mode" @onCreated="onCreated" />
            </div>
          </div>
        </el-dialog>

        <div class="search">
          <el-form :model="form" ref="form" label-width="80px" inline>
            <el-form-item label="标题名称">
              <el-input size="small" clearable v-model="form.title" placeholder="请输入标题"></el-input>
            </el-form-item>

            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search">
                查询
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="toolbar">
          <el-button @click="showEditer = true" type="primary" plain size="mini" icon="el-icon-plus">新增</el-button>
        </div>
        <!-- 表格 -->
        <hltable :isPage="true" :isNumber="table.isNumber" :isChecked="false" :apiurl="table.apiurl"
          :columnEdit="table.columnEdit" :tableColumn="table.column" :formSearch="form" :refreshFlag="table.refreshFlag"
          :selectedRows="table.selectedRows">
          <div slot-scope="selected" slot="EditColumn">
            <el-button size="mini" type="primary" icon="el-icon-search" @click="Edit(selected.singlerow)">
              查看
            </el-button>
            <el-button size="mini" type="danger" icon="el-icon-delete" @click="Del(selected.singlerow)">
              删除
            </el-button>
          </div>
        </hltable>
      </div>
    </div>


  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import { hltable } from "@/components";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import img1 from '@/assets/carousel/26faa51aa93c9e86699e683564ebe40.jpg';
import img2 from '@/assets/carousel/3af98d28e0e86b9ed1fd538747b8745.jpg';
import img3 from '@/assets/carousel/7f84b24a02f5d6940416a9f5069c366.jpg';
import img4 from '@/assets/carousel/f110149f4360a87368ec58cee9d299c.jpg';
export default {
  name: "lzcs",
  components: { Editor, Toolbar, hltable, vueSeamlessScroll },
  data() {
    return {
      imgList: [img1, img2, img3, img4],
      editor: null,
      showEditer: false,
      editorForm: {
        id: "",
        title: "",
        content: "<p>临淄慈善</p>",
      },
      toolbarConfig: {
        excludeKeys: ["uploadVideo"],
      },
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            server: "/api/charitable/uploadImg.json",
            maxNumberOfFiles: 10,
            timeout: 15 * 1000,
            headers: {
              token: localStorage.getItem("systoken"),
            },
          },
        },
      },
      mode: "default", // or 'simple'
      dataList: [
        {
          "time": "2024年6月24日",
          "name": "临淄区行政审批服务局全体职工",
          "sum": "14551"
        },
        {
          "time": "2024年6月25日",
          "name": "临淄区人民检察院全体职工",
          "sum": "26211"
        },
        {
          "time": "2024年6月26日",
          "name": "中共临淄区委办公室全体职工",
          "sum": "15148"
        },
        {
          "time": "2024年6月28日",
          "name": "临淄经济开发区管理委员会全体职工",
          "sum": "6418"
        },
        {
          "time": "2024年6月28日",
          "name": "临淄区辛店街道办事处全体职工",
          "sum": "38500"
        },
        {
          "time": "2024年6月28日",
          "name": "临淄区司法局全体职工",
          "sum": "22530"
        },
        {
          "time": "2024年6月28日",
          "name": "淄博齐鲁化学工业区税务局全体职工",
          "sum": "14900"
        },
        {
          "time": "2024年6月28日",
          "name": "临淄区齐都镇人民政府全体职工",
          "sum": "30800"
        },
        {
          "time": "2024年7月1日",
          "name": "临淄区应急管理局全体职工",
          "sum": "20392"
        },
        {
          "time": "2024年7月1日",
          "name": "临淄区工业和信息化局全体职工",
          "sum": "12748"
        },
        {
          "time": "2024年7月1日",
          "name": "临淄区总工会全体职工",
          "sum": "4492"
        },
        {
          "time": "2024年7月2日",
          "name": "临淄区文学艺术界联合会全体职工",
          "sum": "3814"
        },
        {
          "time": "2024年7月4日",
          "name": "临淄区文化和旅游局全体职工",
          "sum": "30469"
        },
        {
          "time": "2024年7月4日",
          "name": "临淄区金岭回族镇人民政府全体职工",
          "sum": "23027.33"
        },
        {
          "time": "2024年7月5日",
          "name": "临淄区民政局全体职工",
          "sum": "9433"
        },
        {
          "time": "2024年7月5日",
          "name": "临淄区文物局全体职工",
          "sum": "8677"
        },
        {
          "time": "2024年7月8日",
          "name": "临淄区审计局全体职工",
          "sum": "11977"
        },
        {
          "time": "2024年7月10日",
          "name": "临淄区委组织部全体职工",
          "sum": "11955"
        },
        {
          "time": "2024年7月12日",
          "name": "临淄团区委全体职工",
          "sum": "2783"
        },
        {
          "time": "2024年7月12日",
          "name": "临淄区委编办全体职工",
          "sum": "5684"
        },
        {
          "time": "2024年7月15日",
          "name": "临淄区信访局全体职工",
          "sum": "5955"
        },
        {
          "time": "2024年7月18日",
          "name": "临淄区综合行政执法局全体职工",
          "sum": "59643"
        },
        {
          "time": "2024年7月18日",
          "name": "临淄区敬仲镇人民政府全体职工",
          "sum": "28020"
        },
        {
          "time": "2024年7月18日",
          "name": "临淄区妇女联合会全体职工",
          "sum": "2583"
        },
        {
          "time": "2024年7月19日",
          "name": "临淄区科技局全体职工",
          "sum": "4392"
        },
        {
          "time": "2024年7月19日",
          "name": "临淄区发展和改革局全体职工",
          "sum": "36581"
        },
        {
          "time": "2024年7月22日",
          "name": "临淄区委统战部全体职工",
          "sum": "4346.4"
        },
        {
          "time": "2024年7月22日",
          "name": "临淄区人大常委办公室全体职工",
          "sum": "12470"
        },
        {
          "time": "2024年7月24日",
          "name": "淄博市生态环境局临淄分局全体职工",
          "sum": "23429"
        },
        {
          "time": "2024年7月24日",
          "name": "临淄区供销合作社联合社全体职工",
          "sum": "3359"
        },
        {
          "time": "2024年7月25日",
          "name": "临淄区农业农村局全体职工",
          "sum": "33132"
        },
        {
          "time": "2024年7月25日",
          "name": "临淄区财政局全体职工",
          "sum": "18559"
        },
        {
          "time": "2024年7月29日",
          "name": "临淄区雪宫街道办事处全体职工",
          "sum": "32100"
        },
        {
          "time": "2024年7月31日",
          "name": "临淄区自然资源局全体职工",
          "sum": "42151"
        },
        {
          "time": "2024年7月31日",
          "name": "临淄区齐陵街道办事处全体职工",
          "sum": "19974"
        },
        {
          "time": "2024年8月1日",
          "name": "临淄区退役军人事务局全体职工",
          "sum": "10770"
        },
        {
          "time": "2024年8月2日",
          "name": "临淄区凤凰镇人民政府全体职工",
          "sum": "44560"
        },
        {
          "time": "2024年8月2日",
          "name": "临淄区融媒体中心全体职工",
          "sum": "12228"
        },
        {
          "time": "2024年8月7日",
          "name": "临淄区人民政府办公室全体职工",
          "sum": "21252"
        },
        {
          "time": "2024年8月8日",
          "name": "淄博省级农业高新技术产业示范区管理委员会全体职工",
          "sum": "5684"
        },
        {
          "time": "2024年8月8日",
          "name": "淄博齐鲁化学工业区管理委员会全体职工",
          "sum": "12924"
        },
        {
          "time": "2024年8月8日",
          "name": "临淄区住房和城乡建设局全体职工",
          "sum": "31988"
        },
        {
          "time": "2024年8月9日",
          "name": "临淄区气象局全体职工",
          "sum": "1990"
        },
        {
          "time": "2024年8月9日",
          "name": "临淄区科学技术协会全体职工",
          "sum": "1762"
        },
        {
          "time": "2024年8月9日",
          "name": "临淄区投资促进中心全体职工",
          "sum": "4548"
        },
        {
          "time": "2024年8月9日",
          "name": "临淄区朱台镇人民政府全体职工",
          "sum": "38333.16"
        },
        {
          "time": "2024年8月12日",
          "name": "临淄区统计局全体职工",
          "sum": "6402.81"
        },
        {
          "time": "2024年8月12日",
          "name": "临淄区金山镇人民政府全体职工",
          "sum": "50432"
        },
        {
          "time": "2024年8月13日",
          "name": "临淄区消防救援大队全体职工",
          "sum": "10360"
        },
        {
          "time": "2024年8月14日",
          "name": "临淄区人民法院全体职工",
          "sum": "48716"
        },
        {
          "time": "2024年8月14日",
          "name": "临淄区委老干部局全体职工",
          "sum": "4021"
        },
        {
          "time": "2024年8月16日",
          "name": "临淄区商务局全体职工",
          "sum": "5410"
        },
        {
          "time": "2024年8月16日",
          "name": "临淄区皇城镇人民政府全体职工",
          "sum": "28500"
        },
        {
          "time": "2024年8月20日",
          "name": "临淄区人武部全体职工",
          "sum": "3700"
        },
        {
          "time": "2024年8月20日",
          "name": "临淄区政协办公室全体职工",
          "sum": "9611"
        },
        {
          "time": "2024年8月20日",
          "name": "临淄区交通运输局全体职工",
          "sum": "31870"
        },
        {
          "time": "2024年8月21日",
          "name": "临淄区委区直机关工委全体职工",
          "sum": "3000"
        },
        {
          "time": "2024年8月22日",
          "name": "临淄区委党校全体职工",
          "sum": "9282"
        },
        {
          "time": "2024年8月26日",
          "name": "临淄区闻韶街道办事处全体职工",
          "sum": "40050"
        },
        {
          "time": "2024年8月27日",
          "name": "临淄区纪委监委全体职工",
          "sum": "33684"
        },
        {
          "time": "2024年8月29日",
          "name": "临淄区检验检测中心全体职工",
          "sum": "4229"
        },
        {
          "time": "2024年8月29日",
          "name": "临淄区委宣传部全体职工",
          "sum": "7890"
        },
        {
          "time": "2024年8月29日",
          "name": "临淄区残联全体职工",
          "sum": "3705"
        },
        {
          "time": "2024年8月29日",
          "name": "淄博市公安局临淄分局全体职工",
          "sum": "177745"
        },
        {
          "time": "2024年8月30日",
          "name": "临淄区工商业联合会全体职工",
          "sum": "1920"
        },
        {
          "time": "2024年8月30日",
          "name": "临淄区人社局全体职工",
          "sum": "37377"
        },
        {
          "time": "2024年8月30日",
          "name": "临淄区红十字会全体职工",
          "sum": "1996"
        },
        {
          "time": "2024年8月30日",
          "name": "临淄区卫生健康局全体职工",
          "sum": "10097"
        },
        {
          "time": "2024年8月30日",
          "name": "临淄区金山中心卫生院全体职工",
          "sum": "10500"
        },
        {
          "time": "2024年8月30日",
          "name": "临淄区皇城中心卫生院全体职工",
          "sum": "6460"
        },
        {
          "time": "2024年8月30日",
          "name": "临淄区齐陵卫生院全体职工",
          "sum": "7970"
        },
        {
          "time": "2024年8月30日",
          "name": "临淄区敬仲卫生院全体职工",
          "sum": "5660"
        },
        {
          "time": "2024年8月30日",
          "name": "临淄区朱台中心卫生院全体职工",
          "sum": "9640"
        },
        {
          "time": "2024年8月30日",
          "name": "临淄区凤凰中心卫生院全体职工",
          "sum": "10010"
        },
        {
          "time": "2024年8月30日",
          "name": "临淄区齐都中心卫生院全体职工",
          "sum": "13480"
        },
        {
          "time": "2024年8月30日",
          "name": "临淄区人民医院全体职工",
          "sum": "173600"
        },
        {
          "time": "2024年9月2日",
          "name": "临淄区稷下街道办事处全体职工",
          "sum": "40540"
        },
        {
          "time": "2024年9月3日",
          "name": "临淄区口腔医院全体职工",
          "sum": "11623"
        },
        {
          "time": "2024年9月3日",
          "name": "临淄区妇幼保健院全体职工",
          "sum": "77310"
        },
        {
          "time": "2024年9月4日",
          "name": "临淄区委政法委全体职工",
          "sum": "7628.8"
        },
        {
          "time": "2024年9月4日",
          "name": "临淄区疾病预防控制中心全体职工",
          "sum": "23283"
        },
        {
          "time": "2024年9月4日",
          "name": "临淄区疾病预防控制中心（执法大队）全体职工",
          "sum": "9322"
        },
        {
          "time": "2024年9月4日",
          "name": "临淄区中医医院全体职工",
          "sum": "27090"
        },
        {
          "time": "2024年9月4日",
          "name": "临淄区金岭卫生院全体职工",
          "sum": "3829"
        },
        {
          "time": "2024年9月11日",
          "name": "临淄区教体局全体职工",
          "sum": "800000"
        },
        {
          "time": "2024年9月13日",
          "name": "临淄区市场监督管理局全体职工",
          "sum": "59041"
        },
        {
          "time": "2024年9月14日",
          "name": "临淄区水利局全体职工",
          "sum": "19259"
        }
      ],

      //表单信息-e
      form: {
        title: "",
      },
      // 表格属性值
      table: {
        columnEdit: true,
        column: [
          {
            label: "标题名称",
            prop: "title",
            checked: true,
          },
        ],
        apiurl: "api/getCharitableList",
        refreshFlag: "",
        selectedRows: [],
        isNumber: true,
      },
    };
  },
  computed: {
    classOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        isSingleRemUnit: true
      };
    },
  },
  created() { },
  mounted() {
    this.dataRefresh();
  },
  destroyed() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },

  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor);
    },
    handleClose(done) {
      // this.$confirm("确认关闭后，未保存内容会清空。")
      //   .then((_) => {
      //     this.editorForm = {
      //       id: "",
      //       title: "",
      //       html: "<p>临淄慈善</p>",
      //     };
      //     done();
      //   })
      //   .catch((_) => {});
      this.editorForm = {
        id: "",
        title: "",
        html: "<p>临淄慈善</p>",
      };
      done();
    },
    Edit(row) {
      this.editorForm = row;
      this.showEditer = true;
    },
    Del(row) {
      this.$confirm("确定要进行删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store
          .dispatch("api/delCharitable", { ids: [row.id] })
          .then((response) => {
            if (response.success) {
              this.$message.success("操作成功");
              this.dataRefresh();
            } else {
              this.$message.error(response.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    // 查询
    dataRefresh() {
      this.table.refreshFlag = new Date().getTime().toString();
    },
    saveEditor() {
      if (!this.editorForm.title) {
        this.$message.error("请输入标题");
        return;
      }
      const copyForm = Object.assign({}, this.editorForm);
      this.$store
        .dispatch("api/saveCharitable", copyForm)
        .then((response) => {
          if (response.success) {
            this.$message.success("操作成功");
            this.dataRefresh();
          } else {
            this.$message.error(response.message);
          }
          this.showEditer = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";

.edit-view {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
}

.edit-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/deep/ .el-form-item {
  margin-bottom: 0;
}

.lzcs-topImg {
  height: 400px;

  // background-color: red;
  .carousel-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.lzcs-bottom {
  height: calc(100% - 400px);
  // background-color: green;
  display: flex;

  .lzcs-btmLeft {
    width: 50%;
    height: 100%;
    // background-color: aqua;
    overflow: hidden;
  }

  .lzcs-btnRight {
    width: 50%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;

    // background-color: blue;
    /deep/.tableWrapper {
      height: 80% !important;
    }
  }
}

.lzcs-bottom {
  display: flex;
}

.lzcs-btmLeft {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-around;
  font-weight: bold;
  background-color: #f0f0f0;
  padding: 5px 0;
}

.seamless-warp {
  /* 可以添加一些边框等样式增强视觉效果 */
  border: 1px solid #ccc;
}

.name,
.Etiology,
.time {
  width: 33%;
  display: inline-block;
  text-align: center;
}

.no-bullet {
  list-style: none !important;
}
</style>
