<template>
  <div v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)" class="hl-full-container flexVertical">
    <!-- 表单s -->
    <el-drawer :title="dailogTitle" :visible.sync="dialogFormVisible" direction="rtl" size="100%" :modal="false"
      :destroy-on-close="true" class="el-drawer-hl" @close="handleClose">
      <el-form ref="elForm" :model="formData" :disabled="submitType == 'view' ? true : false" :rules="rules"
        size="medium" class="padding15" label-width="120px">
        <el-col :span="24">
          <el-row>

            <el-col :span="12">
        <el-form-item label="所属区划" prop="belongDivision">
          <el-input v-model="formData.belongDivision" placeholder="请输入所属区划" clearable :style="{ width: '100%' }">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="助老食堂名称" prop="canteenName">
          <el-input v-model="formData.canteenName" placeholder="请输入助老食堂名称" clearable :style="{ width: '100%' }">
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="统一社会信用代码" prop="socialCode">
          <el-input v-model="formData.socialCode" placeholder="请输入统一社会信用代码" clearable :style="{ width: '100%' }">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="开办日期" prop="startDate">
          <el-date-picker v-model="formData.startDate" type="date" placeholder="选择日期" value-format="timestamp">
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="服务老年人数" prop="elderlyNum">
          <el-input v-model="formData.elderlyNum" placeholder="请输入服务老年人数" clearable :style="{ width: '100%' }">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="投资金额" prop="investment">
          <el-input v-model="formData.investment" placeholder="请输入投资金额" clearable :style="{ width: '100%' }">
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="建设类型" prop="constructType">
          <el-input v-model="formData.constructType" placeholder="请输入建设类型" clearable :style="{ width: '100%' }">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="运营方式" prop="operateWay">
          <el-input v-model="formData.operateWay" placeholder="请输入运营方式" clearable :style="{ width: '100%' }">
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="在营状态" prop="campStatus">
          <el-input v-model="formData.campStatus" placeholder="请输入在营状态" clearable :style="{ width: '100%' }">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="城乡类别" prop="category">
          <el-input v-model="formData.category" placeholder="请输入城乡类别" clearable :style="{ width: '100%' }">
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="建筑面积" prop="buildArea">
          <el-input v-model="formData.buildArea" placeholder="请输入建筑面积" clearable :style="{ width: '100%' }">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="联系人" prop="contact">
          <el-input v-model="formData.contact" placeholder="请输入联系人" clearable :style="{ width: '100%' }">
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="联系方式" prop="contactTel">
          <el-input v-model="formData.contactTel" placeholder="请输入联系方式" clearable :style="{ width: '100%' }">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="负责人手机号" prop="chargeTel">
          <el-input v-model="formData.chargeTel" placeholder="请输入负责人手机号" clearable :style="{ width: '100%' }">
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="运营时间" prop="operateTime">
          <el-date-picker v-model="formData.operateTime" type="date" placeholder="选择日期" value-format="timestamp">
          </el-date-picker>
        </el-form-item>
      </el-col>

          </el-row>
        </el-col>

        <el-col :span="24">
          <el-row>
            <el-form-item size="large" style="text-align: center">
              <el-button v-show="submitType != 'view' ? true : false" class="qconfirm" type="primary" size="medium"
                @click="submitForm()">提交</el-button>
              <el-button v-show="submitType != 'view' ? true : false" class="qcancle" size="medium"
                @click="resetForm()">
                重置
              </el-button>
            </el-form-item>
          </el-row>
        </el-col>
      </el-form>
    </el-drawer>

    <div class="search">
      <el-form label-width="100px" :model="form" ref="form" inline>
        <el-form-item label="联系人">
          <el-input size="small" clearable v-model="form.contact" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" icon="el-icon-search" @click="dataRefresh">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="toolbar">
      <el-button type="text" plain size="mini" icon="el-icon-upload2">
        <a href="/static/老人信息管理模板.xlsx" download="老人信息管理模板.xlsx">
          模板下载
        </a>
      </el-button>
      <el-upload :headers="token" :action="xlsAreaAction" :show-file-list="false" :before-upload="onUploadBefore"
        :on-success="onXlsUploadSuccess" accept=".xlsx, .xls" style="margin: 0 0.7rem">
        <el-button type="success" plain size="mini" icon="el-icon-upload">
          导入
        </el-button>
      </el-upload>

      <el-button type="primary" plain size="mini" icon="el-icon-download" @click="Download">
        导出
      </el-button>

      <el-button type="primary" plain size="mini" icon="el-icon-plus" @click="Add">
        新增
      </el-button>
      <el-button type="danger" plain size="mini" icon="el-icon-delete" @click="Del">
        删除
      </el-button>
    </div>
    <hltable :isNumber="false" :columnEdit="TableOptions.columnEdit" :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag" :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows" :apiurl="TableOptions.apiurl" @getSelectedRows="getSelectedRows">
      <div slot-scope="selected" slot="EditColumn">
        <el-tooltip effect="dark" content="查看" placement="top">
          <el-button type="warning" size="mini" icon="el-icon-search" circle
            @click="View(selected.singlerow)"></el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="编辑" placement="top">
          <el-button type="primary" icon="el-icon-edit" size="mini" circle
            @click="Edit(selected.singlerow)"></el-button>
        </el-tooltip>
      </div>
    </hltable>
  </div>
</template>

<script>
import { hltable } from "@/components";
export default {
  name: "seniorCanteen",
  components: { hltable },
  data() {
    return {
      loading: false,
      token: { token: localStorage.getItem("systoken") },
      // 表单内容
      dailogTitle: "",
      dialogFormVisible: false,
      submitType: "",
      // 查询条件
      form: {
        name: "",
      },
      // 附件预览列表
      imgList: [],
      fileList: [],
      // 导入接口
      xlsAreaAction: "/api/elderlyCanteen/importData.json",
      // 新增/修改 表单
      timeValue: [],
      formData: {
        belongDivision: '',
        canteenName: '',
        socialCode: '',
        startDate:'',
        elderlyNum: '',
        investment: '',
        constructType: '',
        operateWay: '',
        campStatus: '',
        category: '',
        buildArea: '',
        contact: '',
        contactTel: '',
        chargeTel: '',
        operateTime: null
      },
      // 表单规则
      rules: {
        // chargeName: [
        //   {
        //     required: true,
        //     message: "请输入负责人姓名",
        //     trigger: "blur",
        //   },
        // ],
        // facilityName: [
        //   {
        //     required: true,
        //     message: "请输入设备名称",
        //     trigger: "blur",
        //   },
        // ],
        // status: [
        //   {
        //     required: true,
        //     message: "请输入运营状态",
        //     trigger: "blur",
        //   },
        // ],
        // operateTime: [
        //   {
        //     required: true,
        //     message: "请选择运营时间",
        //     trigger: "blur",
        //   },
        // ],
      },
      // 列表设置
      TableOptions: {
        selectedRows: [],
        apiurl: "api/zzstDataList",
        refreshFlag: "",
        columnEdit: true,
        column: [
          {
            label: "所属区划",
            prop: "belongDivision",
            checked: true,
          },
          {
            label: "建筑面积",
            prop: "buildArea",
            checked: true,
          },
          {
            label: "助老食堂",
            prop: "canteenName",
            checked: true,
          },
          {
            label: "城乡类别",
            prop: "category",
            checked: true,
          },
          {
            label: "建设类型",
            prop: "constructType",
            checked: true,
          },
          {
            label: "联系人",
            prop: "contact",
            checked: true,
          },
          {
            label: "联系方式",
            prop: "contactTel",
            checked: true,
          },
          {
            label: "服务老年人数",
            prop: "elderlyNum",
            checked: true,
          },
          {
            label: "开办日期",
            prop: "startDate",
            checked: true,
            formatter: this.timeFormatter,
          },
        ],
      },
    };
  },
  async mounted() {
    await this.loadData();
    this.dataRefresh();
  },
  methods: {
    // 查询
    dataRefresh() {
      this.TableOptions.refreshFlag = new Date().getTime().toString();
    },
    // 时间格式化
    timeFormatter(row, column, cellValue) {
      // return cellValue ? this.$timestampTostr(cellValue) : "";
      const date = new Date(cellValue * (cellValue.toString().length === 10 ? 1000 : 1));
      // 获取年份
      const year = date.getFullYear();
      // 获取月份，注意月份是从 0 开始的，所以要加 1，并且保证是两位数
      const month = String(date.getMonth() + 1).padStart(2, '0');
      // 获取日期，保证是两位数
      const day = String(date.getDate()).padStart(2, '0');
      // 拼接成 yyyy-mm-dd 格式
      return `${year}-${month}-${day}`;
    },
    // 加载数据
    async loadData() { },
    // 新增
    Add() {
      this.setformType("add", "新增");
    },
    // 查看
    View(row) {
      this.setformType("view", "查看");
      this.loadform(row);
    },
    // 编辑
    Edit(row) {
      this.setformType("edit", "编辑");
      this.loadform(row);
    },
    // 设置表单状态
    setformType(type, title) {
      this.dialogFormVisible = true;
      this.dailogTitle = title;
      this.submitType = type;
    },
    loadform(row) {
      const rowinfo = Object.assign({}, row);
      this.formData = rowinfo;
      this.imgList = rowinfo.img.split(",");
      this.imgList.forEach((file) => {
        this.fileList.push({ url: file });
      });
      this.timeValue = [rowinfo.rentalTime, rowinfo.returnTime];
    },
    // 弹窗关闭前处理
    handleClose() {
      this.resetForm();
    },
    // 删除
    Del() {
      if (this.TableOptions.selectedRows.length == 0) {
        this.$message({
          type: "error",
          message: "请勾选下方表格中的数据",
        });
        return false;
      }
      var ids = [];
      this.TableOptions.selectedRows.forEach((key) => {
        ids.push(key.id);
      });
      this.$confirm("确定要进行删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store
          .dispatch("api/zzstDeleteData", { ids: ids })
          .then((response) => {
            if (response.success) {
              this.$message.success("操作成功");
              this.dataRefresh();
            } else {
              this.$message.error(response.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    // 提交
    submitForm() {
      // if (this.imgList.length == 0) {
      //   this.$message("请选择图片");
      //   return;
      // }
      // if (this.timeValue.length == 0) {
      //   this.$message("请选择时间");
      //   return;
      // }
      this.$refs["elForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          const copyForm = Object.assign({}, this.formData);
          if (this.submitType == "add") {
            copyForm.id = "";
          }
          copyForm.img = this.imgList.join(",");
          copyForm.startTime = this.timeValue[0];
          copyForm.endTime = this.timeValue[1];
          this.$store
            .dispatch("api/zzstSaveData", copyForm)
            .then((response) => {
              if (response.success) {
                this.$message.success("操作成功");
                this.dataRefresh();
              } else {
                this.$message.error(response.message);
              }
              this.dialogFormVisible = false;
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 重置
    resetForm() {
      const th = this;
      Object.keys(this.formData).forEach(function (key) {
        th.formData[key] = "";
      });
      this.imgList = [];
      this.fileList = [];
      this.timeValue = [];
    },
    // 文件上传前
    onUploadBefore() {
      this.loading = true;
    },
    // 导入
    onXlsUploadSuccess(response) {
      if (response.success) {
        this.$message({
          type: "success",
          message: "导入成功",
        });
        this.dataRefresh();
      } else {
        this.$message({
          type: "error",
          message: "导入失败",
        });
      }
      this.loading = false;
    },
    /**
     * 附件上传
     */
    success(response, file) {
      this.imgList.push(response.result);
    },
    beforeUpload(file) {
      let isAccept = new RegExp("image/*").test(file.type);
      if (!isAccept) {
        this.$message.error("应该选择图片类型的文件");
      }
      return isAccept;
    },
    handleRemove(file, fileList) {
      this.imgList = [];
      if (fileList.length > 0) {
        fileList.forEach((item) => {
          this.imgList.push(item.response ? item.response.result : item.url);
        });
      }
    },
    // 导出
    async Download() {
      this.loading = true;
      const downloadFrom = Object.assign({}, this.form);
      this.$store
        .dispatch("api/zzstExportData", downloadFrom)
        .then((response) => {
          let blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          let fileName = "农村幸福院" + ".xlsx";
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            let link = document.createElement("a");

            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            // 释放内存
            window.URL.revokeObjectURL(link.href);
          }
          this.loading = false;
          this.$message.success("导出成功");
        });
    },

    // 获取选中项
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/tabletoptool.scss";
</style>
