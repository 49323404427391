<template>
  <div class="homeIndex">
    <!-- 顶部数据展示 -->
    <div class="top">
      <div class="box" v-for="(item, index) in topData" :key="index">
        <span class="line" :style="{ background: item.rgb }"></span>
        <img :src="item.img" alt="" />
        <div class="content">
          <span>{{ item.name }}</span>
          <span>
            <b style="font-size: 1.2rem">{{ item.num }}</b> 人</span>
        </div>
      </div>
    </div>
    <!-- 补贴统计 -->
    <div class="second">
      <div class="content">
        <div class="title">
          <div class="label">
            <i></i>
            <b>补贴统计</b>
          </div>
          <div class="checkDate">
            <div v-for="(item, index) in navList" :key="index" :class="item.active ? 'active' : ''"
              @click="checkTime(index)">
              <span>
                {{ item.name }}
              </span>
            </div>
          </div>
        </div>
        <div class="details">
          <div class="li" v-for="(item, index) in detailsList" :key="index">
            <div class="liTop">
              <i></i>
              <span>{{ item.name }}</span>
            </div>
            <div class="liCen">
              <span><b style="font-size: 1.5rem">{{ item.num }}</b>万元</span>
            </div>
            <div class="liBot">
              <el-progress :show-text="false" :stroke-width="26" :percentage="item.percentage"
                :color="item.rgb"></el-progress>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 三饼图 -->
    <div class="pieBox">
      <div class="section">
        <div class="title">
          <i></i>
          <span>从业人员类别</span>
        </div>
        <div class="chartBox">
          <pieCharts :data="seriesData" :data1="bgSeriesData" />
        </div>
      </div>
      <div class="section">
        <div class="title">
          <i></i>
          <span>志愿者性别</span>
        </div>
        <div class="chartBox">
          <pieCharts :data="seriesData1" :data1="bgSeriesData1" />
        </div>
      </div>
      <div class="section">
        <div class="title">
          <i></i>
          <span> 家庭床位建床老人 </span>
        </div>
        <div class="chartBox">
          <pieCharts :data="seriesData2" :data1="bgSeriesData2" />
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="bottom">
      <div class="barBox">
        <div class="title">
          <div>
            <i></i>
            <span> 工单统计 </span>
          </div>
        </div>

        <div class="chartBox">
          <div id="bar"></div>
        </div>
      </div>
      <div class="rightPie">
        <div class="title">
          <div>
            <i></i>
            <span> 探访关爱 </span>
          </div>
        </div>
        <div class="chartBox">
          <pieCharts :data="seriesData" :data1="bgSeriesData" />
        </div>
      </div>
    </div>
    <Drawer :title="title" ref="drawerIshow" :drawerSize="drawerSize" :append-to-body="true">
      <oldIndex></oldIndex>
    </Drawer>
  </div>
</template>

<script>
import pieCharts from "./components/pie.vue";
import { Drawer } from "@/components";
import oldIndex from "./components/index";
export default {
  name: "homePage",
  components: { oldIndex, Drawer, pieCharts },
  data() {
    return {
      drawerSize: "100%",
      topData: [],
      navList: [
        { name: "本周", active: true },
        { name: "本月", active: false },
        { name: "今年", active: false },
        { name: "全部", active: false },
      ],
      navList1: [
        { name: "本周", active: true },
        { name: "本月", active: false },
        { name: "今年", active: false },
      ],
      detailsList: [
        {
          name: "补贴总金额",
          num: "0.00",
          rgb: "rgb(37, 205, 233)",
          percentage: 100,
        },
        {
          name: "机构建设补贴",
          num: "0.00",
          rgb: "rgb(37, 205, 233)",
          percentage: 50,
        },
        {
          name: "机构运营补贴",
          num: "0.00",
          rgb: "rgb(34, 227, 172)",
          percentage: 50,
        },
        {
          name: "老人高龄补贴",
          num: "0.00",
          rgb: "rgb(253, 192, 92)",
          percentage: 50,
        },
        {
          name: "老人特困补贴",
          num: "0.00",
          rgb: "rgb(157, 171, 255)",
          percentage: 50,
        },
        {
          name: "老人失能护理补贴",
          num: "0.00",
          rgb: "rgb(251, 143, 143)",
          percentage: 50,
        },
      ],
      // 图表数据
      seriesData: [
        {
          name: "111",
          value: "222",
          itemStyle: {
            color: "#FFA965",
          },
        },
        {
          name: "222",
          value: "333",
          itemStyle: {
            color: "#6BA5FF",
          },
        },
      ],
      // 图表中间圈内的数据，数据与图表要一致，只改变颜色
      bgSeriesData: [
        {
          name: "111",
          value: "222",
          itemStyle: {
            color: "rgba(255, 169, 101, 0.4)",
          },
        },
        {
          name: "222",
          value: "333",
          itemStyle: {
            color: "rgba(107, 165, 255, 0.4)",
          },
        },
      ],
      seriesData1: [
        {
          name: "333",
          value: "444",
          itemStyle: {
            color: "#FFA965",
          },
        },
        {
          name: "555",
          value: "666",
          itemStyle: {
            color: "#6BA5FF",
          },
        },
      ],
      bgSeriesData1: [
        {
          name: "333",
          value: "444",
          itemStyle: {
            color: "rgba(255, 169, 101, 0.4)",
          },
        },
        {
          name: "555",
          value: "666",
          itemStyle: {
            color: "rgba(107, 165, 255, 0.4)",
          },
        },
      ],
      seriesData2: [
        {
          name: "777",
          value: "888",
          itemStyle: {
            color: "#FFA965",
          },
        },
        {
          name: "999",
          value: "111",
          itemStyle: {
            color: "#6BA5FF",
          },
        },
      ],
      bgSeriesData2: [
        {
          name: "777",
          value: "888",
          itemStyle: {
            color: "rgba(255, 169, 101, 0.4)",
          },
        },
        {
          name: "999",
          value: "111",
          itemStyle: {
            color: "rgba(107, 165, 255, 0.4)",
          },
        },
      ],
      title: "老人总数",
      mycharts: null,
    };
  },
  mounted() {
    this.getLineChart();
    this.getTypeDate()
    this.getMealSite()
  },
  methods: {
    // 获取老人床位总数
    async getMealSite() {
      let params = {
        "query": {
          "name": "",
          "sex": "",
          "idCard": "",
          "areaCode": "370305000000"
        },
        "currentPage": 1,
        "size": 20
      }
      const siteData = await this.$store.dispatch("api/getElderlyInfoList", { params });
      if (siteData.success) {
        console.log("resssssssssss", siteData)
        this.topData = [
          {
            name: "助餐老人数",
            num: siteData.result.total,
            img: require("../../assets/default/img_logo.png"),
            rgb: "rgb(41, 241, 156)",
          },
          {
            name: "家床老人数",
            num: '23456',
            img: require("../../assets/default/img_logo.png"),
            rgb: "rgb(254, 220, 69)",
          },
          {
            name: "机构老人数",
            num: '12344',
            img: require("../../assets/default/img_logo.png"),
            rgb: "rgb(102, 158, 240)",
          },
          {
            name: "社区老人数",
            num: '34567',
            img: require("../../assets/default/img_logo.png"),
            rgb: "rgb(255, 158, 135)",
          },
          {
            name: "居家老人数",
            num: '23714',
            img: require("../../assets/default/img_logo.png"),
            rgb: "rgb(255, 167, 205)",
          }
        ]
      } else {
        this.topData = [
          {
            name: "助餐老人数",
            num: '94080',
            img: require("../../assets/default/img_logo.png"),
            rgb: "rgb(41, 241, 156)",
          },
          {
            name: "家床老人数",
            num: '23456',
            img: require("../../assets/default/img_logo.png"),
            rgb: "rgb(254, 220, 69)",
          },
          {
            name: "机构老人数",
            num: '12344',
            img: require("../../assets/default/img_logo.png"),
            rgb: "rgb(102, 158, 240)",
          },
          {
            name: "社区老人数",
            num: '34567',
            img: require("../../assets/default/img_logo.png"),
            rgb: "rgb(255, 158, 135)",
          },
          {
            name: "居家老人数",
            num: '23714',
            img: require("../../assets/default/img_logo.png"),
            rgb: "rgb(255, 167, 205)",
          }
        ]
        this.$message.error(siteData.message);
      }
    },
    // 切换时间
    checkTime(e) {
      var array = this.navList;
      for (let i = 0; i < array.length; i++) {
        array[i].active = false;
        if (!array[e].active) {
          array[e].active = true;
        }
      }
    },
    checkTime1(e) {
      var array = this.navList1;
      for (let i = 0; i < array.length; i++) {
        array[i].active = false;
        if (!array[e].active) {
          array[e].active = true;
        }
      }
    },
    // 点击头部
    add(e, b) {
      if (b == 0) {
        this.$refs.drawerIshow.drawer = true;
      }
    },
    getLineChart() {
      this.mycharts = this.$echarts.init(document.getElementById("bar"));
      var option = {
        backgroundColor: "white",
        grid: {
          top: "20%",
          left: "5%",
          right: "15%",
          bottom: "8%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          borderWidth: 1,
          axisPointer: {
            type: "shadow",
          },
          extraCssText: "z-index:2",
        },
        legend: [
          {
            top: "top",
            left: "center",
            orient: "horizontal",
            data: ["服务次数", "补贴统计", "探访次数"],
            itemWidth: 15,
            itemHeight: 10,
            itemGap: 15,
            borderRadius: 4,
            textStyle: {
              color: "#000",
              fontFamily: "Alibaba PuHuiTi",
              fontSize: 14,
              fontWeight: 400,
            },
          },
        ],
        xAxis: {
          type: "category",
          data: [
            "2013-6-12",
            "2013-6-13",
            "2013-6-14",
            "2013-6-15",
            "2013-6-16",
          ],
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#393939", //X轴文字颜色
            },
          },
        },
        yAxis: [
          {
            type: "value",
            name: "",
            nameTextStyle: {
              color: "#000",
              fontFamily: "Alibaba PuHuiTi",
              fontSize: 14,
              fontWeight: 600,
              // padding: [0, 0, 0, 40], // 四个数字分别为上右下左与原位置距离
            },
            nameGap: 30, // 表现为上下位置
            axisLine: {
              show: true,
              lineStyle: {
                color: "#eeeeee",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: "#393939",
              fontSize: 14,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#eeeeee",
              },
            },
          },
        ],
        series: [
          {
            name: "服务次数",
            type: "line",
            smooth: true,
            // showAllSymbol: true, //显示所有图形。
            //标记的图形为实心圆
            symbolSize: 8, //标记的大小
            itemStyle: {
              //折线拐点标志的样式
              color: "white",
              borderWidth: "2",
              borderColor: "#5470c6",
              normal: {
                color: "#5470c6", //拐点颜色
              },
            },
            lineStyle: {
              color: "#5470c6",
            },
            data: [175, 160, 153, 121, 156],
          },
          {
            name: "补贴统计",
            type: "line",
            smooth: true,
            showAllSymbol: true, //显示所有图形。
            symbolSize: 8, //标记的大小
            itemStyle: {
              //折线拐点标志的样式
              color: "white",
              borderWidth: "2",
              borderColor: "#91cc75",
              normal: {
                color: "#91cc75", //拐点颜色
              },
            },
            lineStyle: {
              color: "#91cc75",
            },
            data: [0, 0, 0, 0, 0],
          },
          {
            name: "探访次数",
            type: "line",
            smooth: true,
            showAllSymbol: true, //显示所有图形。
            symbolSize: 8, //标记的大小
            itemStyle: {
              //折线拐点标志的样式
              color: "white",
              borderWidth: "2",
              borderColor: "#f3454b",
              normal: {
                color: "#f3454b", //拐点颜色
              },
            },
            lineStyle: {
              color: "#f3454b",
            },
            data: [0, 0, 0, 0, 0],
          },
        ],
      };
      this.mycharts.setOption(option);
    },
    getTypeDate() {
      // this.$store.dispatch("homePage/getTypeDate").then((res) => {
      //   this.topData[0].num = res.result.count
      //   this.topData[1].num = res.result.supplementCount
      //   this.topData[2].num = res.result.bedCount
      //   this.topData[3].num = res.result.organCount
      //   this.topData[4].num = res.result.communityCount
      //   this.topData[5].num = res.result.liveCount
      // })
    }
  },
};
</script>

<style lang="scss" scoped>
.homeIndex {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.top {
  width: 100%;
  height: 9rem;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .box {
    // width: 16%;
    width: 18%;
    height: 7rem;
    background: white;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    .line {
      width: 6px;
      height: 25px;
      border-radius: 5px;
      margin-right: 2.2rem;
      cursor: pointer;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1rem;
      margin-left: 2.5rem;

      span {
        margin-top: 0.2rem;
      }
    }
  }
}

.second {
  width: 100%;
  display: flex;
  justify-content: center;

  .content {
    width: 99%;
    height: 11rem;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      width: 98%;
      height: 2.3rem;
      border-bottom: 1px solid #dcdfe6;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        display: flex;
        align-items: center;
        font-size: 1rem;

        i {
          display: inline-block;
          width: 5px;
          height: 14px;
          background-color: #2362fb;
          margin: 0 1rem;
        }
      }

      .checkDate {
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: #999;

        div {
          width: 2.5rem;
          margin: 0 1rem;
          text-align: center;
          border-bottom: 1px solid rgba(35, 98, 251, 0);
        }

        .active {
          border-bottom: 1px solid #2362fb;
        }
      }
    }

    .details {
      width: 95%;
      height: calc(100% - 2.3rem);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .li {
        width: 15%;
        display: flex;
        flex-direction: column;

        .liTop {
          font-size: 1rem;
          color: #999;

          i {
            display: inline-block;
            width: 8px;
            height: 8px;
            background: #1890ff;
            border-radius: 50%;
            margin-top: 5px;
            margin-right: 5px;
          }
        }

        .liCen {
          font-size: 1.1rem;
        }

        .liBot {
          width: 100%;
        }
      }
    }
  }
}

::v-deep .el-progress-bar__outer {
  width: 100%;
  height: 1rem !important;
  margin-top: 0.5rem;
}

.pieBox {
  width: 99%;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .section {
    width: 31%;
    height: 14rem;
    background: white;

    .title {
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: 700;
      margin: 1rem;
    }

    i {
      display: inline-block;
      width: 5px;
      height: 14px;
      background-color: #2362fb;
      margin: 0 1rem;
    }

    .chartBox {
      width: 100%;
      height: calc(100% - 3rem);
    }
  }
}

.bottom {
  width: 99%;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 700;
    margin: 1rem;

    .checkDate {
      display: flex;
      align-items: center;
      font-size: 1rem;
      color: #999;

      div {
        width: 2.5rem;
        margin: 0 1rem;
        text-align: center;
        border-bottom: 1px solid rgba(35, 98, 251, 0);
      }

      .active {
        border-bottom: 1px solid #2362fb;
      }
    }
  }

  i {
    display: inline-block;
    width: 5px;
    height: 14px;
    background-color: #2362fb;
    margin: 0 1rem;
  }

  .chartBox {
    width: 100%;
    height: calc(100% - 3rem);

    #bar {
      width: 100%;
      height: 100%;
    }
  }

  .barBox {
    width: 65.5%;
    height: 14rem;
    background: white;
  }

  .rightPie {
    width: 31%;
    height: 14rem;
    background: white;
  }
}
</style>
